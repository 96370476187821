import React, { useState } from 'react';
import './Login.css';

function Login({ onLoginSuccess , onCloseClicked}) {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;

    const handleLogin = async (event) => {
        event.preventDefault();
    
        const credentials = { username, password };

        const raw = JSON.stringify({
            "telephoneNumber": credentials.username,
            "password": credentials.password
        });
    
        try {
          const response = await fetch(apiUrl+'/api/user/login', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: raw
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
    
          const data = await response.json();
          onLoginSuccess(data.data.jwt, credentials.username);  // Handle the JWT in your app, maybe save it in localStorage
        } catch (error) {
          console.error("Login failed:", error);
        }
      };

    const closeClicked = () => {
        onCloseClicked();
    }
    return (
        <div className="login-container" style={{backgroundImage: 'url(/login_form_444_354.png)'}}>
            <button className="closeButton" onClick={closeClicked}>
                X
            </button>
            <form onSubmit={handleLogin} className="login-form">
                <div className="login-input-container">
                    <input 
                        className='transparent-input'
                        type="text"
                        placeholder="Phone Number"
                        onChange={e => setUsername(e.target.value.toString())}
                        autoComplete="off"  // Correct autoComplete value
                    />
                    <input
                        input className='transparent-input'
                        type="password"
                        placeholder="Password"
                        onChange={e => setPassword(e.target.value.toString())}
                        autoComplete="off"
                    />
                </div>
                
                <button type="submit">Login</button>
            </form>
        </div>
      );
}

export default Login;