import React from 'react';
import { Modal } from 'antd';
import { useModal } from './ModalContext';
import '../pages/PromotionPage.css';

const PromotionModal = () => {
  const { modalState, closeModal } = useModal();
  const { isVisible, data } = modalState;

  if (modalState.type !== 'promotion') return null;

  return (
    <Modal
      title={data?.title}
      open={isVisible}
      footer={null}
      onCancel={closeModal}
      width={900}
    >
      {data && (
        <div>
          <img src={data.imgUrlHorizontal} alt={data.title} className="modal-image" />
          <h1>{data.name}</h1>
          <p>{data.description}</p>
          <p>Promotion Code: {data.promotionCode}</p>
        </div>
      )}
    </Modal>
  );
};

export default PromotionModal;
