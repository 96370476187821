import React, {useEffect, useState} from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import './UserDetail.css';

function InviteFriend(){

    const { auth } = useAuth();
    const [inputValue, setInputValue] = useState('');

    useEffect(() => {
        console.log(auth.userData.ownedAffiliate.code);
        if (auth.isAuthenticated && auth.userData) {
            
            let affiliateUrl = process.env.REACT_APP_URL+"?code="+auth.userData.ownedAffiliate.code;
            setInputValue(affiliateUrl);
        }
    }, [auth]);

    const handleCopy = () => {
        navigator.clipboard.writeText(inputValue).then(() => {
            alert('Copied to clipboard!');
        }).catch(err => {
            alert('Failed to copy!');
        });
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>InviteFriend</h1>

                <div className="user-detail-data">
                    <h1>Your Affiliate Link</h1>

                    <div style={{ marginTop: '20px' }}>
                        <Form.Item>
                            <Input value={inputValue} />
                        </Form.Item>

                        <Button type="primary" onClick={handleCopy}>Copy</Button>
                    </div>
                </div>
            </div>

        </div>
    );
}

export default InviteFriend;