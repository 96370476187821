import logo from '../../logo.svg';
import '../../App.css';

import Header from '../layout/Header';
import MainSideBar from '../layout/MainSideBar';
import MainCover from '../layout/SportPage/MainCover'
import Footer from '../layout/Footer';

import { useModal } from '../modal/ModalContext';


import React, {createContext, useContext, useEffect, useState, useCallback, useRef, useReducer  } from 'react';
import Login from '../common/Login';
import { AuthProvider, useAuth } from '../../AuthContext';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import Register from '../common/Register';
import InfiniteScroll from 'react-infinite-scroll-component';
import './InviteFriendPage.css';
import PromotionModal from '../modal/PromotionModal';
import RankModal from '../modal/RankModal';

import { Modal, Button } from 'antd';

function InviteFriendPage(){
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { login , auth} = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const [games, setGames] = useState([]);
  const [page, setPage] = useState(0);
  const [hasMore, setHasMore] = useState(true);

  const [providers, setProviders] = useState([]);
  const [selectedProvider, setSelectedProvider] = useState(null);

  const [selectedCategory, setSelectedCategory] = useState(null);

  const [promotions, setPromotions] = useState([]);

  const userDetailWindowRef = useRef(null);

  const isFirstRender = useRef(true);

  const [selectedPromotion, setSelectedPromotion] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);

  const [loadingProvider, setLoadingProvider] = useState(false);

  const { openModal } = useModal();
  
  
  const handleLoginClick = () => {
    setShowLogin(!showLogin);
  };

  const handleRegisterClick = () => {
    setShowRegister(!showRegister);
  };

  const handleLoginSuccess = (token, username) => {
    login(token, username);
    setShowLogin(false);
    // console.log("Logged in with token:", token);
    // console.log("Logged in with username:", username);
  };

  const handleOtpSendSuccess = (token) => {
    console.log("OTP Token:", token);
  }

  const handleOtpValidate = (code) => {
    console.log("OTP Validate:", code);
  }

  const handleRegisterSuccess = (token, username) => {
    login(token, username);
    setShowLogin(false);
    // console.log("Logged in with token:", token);
    // console.log("Logged in with username:", username);
  };

  const handleSupportClick = async () => {

    if (!auth.token) {
      console.error("No token available");
      return;
    }
    const myHeaders = new Headers();
    
    myHeaders.append("Authorization", `Bearer ${auth.token}`);
    console.log(myHeaders);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(apiUrl+"/api/secure-data", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  }

  const loginCloseClicked = () => {
    setShowLogin(false);
  }

  const registerCloseClicked = () => {
    setShowRegister(false);
  }

  const handleUserDetail = (event) => {
    event.preventDefault(); // Prevent the default link behavior

    // Check if the window already exists and is not closed
    if (userDetailWindowRef.current && !userDetailWindowRef.current.closed) {
      userDetailWindowRef.current.focus(); // Focus on the existing window
      userDetailWindowRef.current.location.reload();
      return;
    }

    userDetailWindowRef.current = window.open(
      '',
      '_blank',
      'width=600,height=700,toolbar=no,scrollbars=yes,resizable=yes'
    );

    const syncAuthContext = () => {
      userDetailWindowRef.current.localStorage.setItem('winner-jwt', auth.token);
      userDetailWindowRef.current.localStorage.setItem('username', auth.username);
      userDetailWindowRef.current.React = React; // Pass React to the new window
      userDetailWindowRef.current.ReactDOM = require('react-dom'); // Pass ReactDOM to the new window
      userDetailWindowRef.current.history.pushState({}, '', '/user/detail');
    };

    userDetailWindowRef.current.onload = syncAuthContext;
    userDetailWindowRef.current.location.href = `${window.location.origin}/user/detail/account`;
  };

  useEffect(() => {
    fetchPromotions();
  }, []);

  const fetchPromotions = async() => {
    const apiUrl = `${process.env.REACT_APP_API_URL}/member/promotion/all`;
    const requestOptions = {
        method: "GET",
        // headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" }
        headers: {"Content-Type": "application/json" }
    };

    try {
        const response = await fetch(apiUrl, requestOptions);
        if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
        }
        const result = await response.json();
        setPromotions(result);
        console.log(promotions);
    } catch (error) {
        console.error('Error Deposit Request:', error);
    }
  } 

  const promotionInfo = (promotion) => {
    setSelectedPromotion(promotion);
    setIsModalVisible(true);
    openModal('promotion', promotion);
  }

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  return (
    <>
      <Header onLoginClick={handleLoginClick} onRegisterClick={handleRegisterClick} 
              onSupportClick={handleSupportClick} onUserDetail={handleUserDetail}
      />
        {showLogin && <Login onLoginSuccess={handleLoginSuccess} onCloseClicked={loginCloseClicked}/>}
        {showRegister && <Register onCloseClicked={registerCloseClicked}/>}

        <div className="layout" style={{backgroundImage: 'url(/background_3_pages.png)', backgroundSize: 'cover'}}>
          <main className="main-content">
            <div className="promotion-page">
                <h1 style={{color: '#DAA520'}} className="promotion-title">Invite Friend</h1>
                
                <div style={{ height: '800px', width: '800px', backgroundImage: 'url(/invitefriend_background.png)', borderRadius: '20px', backgroundSize: 'cover', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h1 style={{color: 'white'}}>เชิญเพื่อนมาเล่นด้วยกันสิ</h1>
                  <h2 style={{color: 'white'}}>รับคอมมิชชั่นสูงสุด 3%</h2>
                </div>

              <PromotionModal />
              
            </div>
          </main>
        </div>
      <Footer />
    </>
  );
}

export default InviteFriendPage;