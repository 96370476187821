import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import './UserDetail.css';

function DepositPaypay89(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();

    const onFinish = async(values) => {
        console.log('Received values:', values);

        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/deposit/getpaypay89?username=" + auth.username+"&amount="+values.amount+"&promoCode="+values.promoCode, requestOptions);
            if (response.ok) {
                const url = await response.text();
                console.log("URL received:", url);

                // Create an anchor element for redirection
                const a = document.createElement('a');
                a.href = url;
                a.rel = 'noopener noreferrer';
                
                console.log("Attempting to redirect to:", url);
                a.click(); // Trigger the redirection

                // Log success if redirected
                console.log("Redirection successful to:", url);
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Deposit Paypay89</h1>

                <div className="user-detail-data">
                    
                    <Form form={form} 
                        onFinish={onFinish}
                        className="deposit-form" layout="vertical" 
                        initialValues={{
                        enable: true
                    }}>
                        <h1>Enter Deposit Amount</h1>
                        <Form.Item name="amount" label="Balance" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="promoCode" label="Promotion Code" rules={[{ required: false }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item style={{justifySelf: 'center', alignSelf: 'center'}}>
                            <Button type="primary" className="deposit-button" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
  );
}

export default DepositPaypay89;