import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import './UserDetail.css';
import './Account.css'

function AddBankAccount(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        console.log("auth", auth);
        if(auth.userData){
            form.setFieldsValue({
                memberId: auth.userData.memberId,
            });
        }
        
    }, [auth]);

    const addBankAccount = async(values) => {
        console.log('Form values:', JSON.stringify(values));        

        const method = "POST";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/add`;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            body: JSON.stringify(values)
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account added successfully!');
            navigate(`/user/detail/managebank`);

        } catch (error) {
            console.error('Error posting member:', error);
        }
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Add Bank Account</h1>

                <div className="user-detail-data">
                    <Form form={form} 
                        className="account-form" layout="vertical" 
                        onFinish={addBankAccount}
                        initialValues={{
                        enable: true,
                        
                    }}>
                        <Form.Item name="bank" label="Select Bank to add" rules={[{ required: true }]}>
                            <Select placeholder="Select Bank Account">
                                <Select.Option value="SCB">Siam Commercial Bank</Select.Option>
                                <Select.Option value="KBANK">Kasikorn Bank</Select.Option>
                                <Select.Option value="KTB">Krung Thai Bank</Select.Option>
                                <Select.Option value="BBL">Bangkok Bank</Select.Option>
                            </Select>
                        </Form.Item>

                        <Form.Item
                            name="memberId"
                            hidden
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item name="accountNumber" label="Account Number" rules={[{ required: true }]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item name="fullname" label="Full Name" rules={[{ required: true }]}>
                            <Input/>
                        </Form.Item>

                        <Form.Item>
                            <Button type="primary" htmlType="submit">
                            Add Bank Account
                            </Button>
                        </Form.Item>
                    </Form>
                </div>

                {/* <p style={{color: 'white'}}>Welcome, {auth.username}</p>
                <p style={{color: 'white'}}>You are {auth.isAuthenticated ? "authenticated" : "not authenticated"}.</p> */}
            </div>
        </div>
    );
}

export default AddBankAccount;