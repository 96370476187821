import React, { useState, useEffect } from 'react';
import './SlotSideBar.css';
import InfiniteScroll from 'react-infinite-scroll-component';

function SlotSideBar({onProviderSelect}) {

    const [loadingProvider, setLoadingProvider] = useState(false);
    const [gameProviders, setGameProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
    const [hasMore, setHasMore] = useState(true);
    const [page, setPage] = useState(0);
    const limit = 10; // Number of providers to fetch per request
    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        fetchProviders();
    }, []);

    const fetchProviders = async () => {
        if (loadingProvider) return;
        setLoadingProvider(true);

        try {
            const response = await fetch(`${apiUrl}/game/get-provider-lists?page=${page}&limit=${limit}&gameType=SLOT`);
            const data = await response.json();

            if (response.ok && Array.isArray(data)) {
                setGameProviders(prevProviders => [...prevProviders, ...data]);
                if (data.length < limit) {
                    setHasMore(false); // No more data to load
                } else {
                    setPage(prevPage => prevPage + 1);
                }
            }
        } catch (error) {
            console.error('Error fetching providers:', error);
        } finally {
            setLoadingProvider(false);
        }
    };

    const handleProviderClick = (providerName) => {
        setSelectedProvider(providerName);
        onProviderSelect(providerName);
    };

    return (
        <aside className="slot-sidebar">
            <InfiniteScroll
                dataLength={gameProviders.length}
                next={fetchProviders}
                hasMore={hasMore}
                loader={<h4>..Loading..</h4>}
            >
                <ul className="slot-ul">
                    {gameProviders.map((provider, index) => (
                        <li key={index}>
                            <img className={`provider-button ${selectedProvider === provider.name ? "selected" : ""}`}
                                src={provider.logoUrl}
                                onClick={() => handleProviderClick(provider.name)}
                                alt={provider.name}
                            />
                        </li>
                    ))}
                </ul>
            </InfiniteScroll>
        </aside>
    );
}

export default SlotSideBar;