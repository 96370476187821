import { useState, useEffect, useCallback } from 'react';

const useGameData = (apiUrl, gameType = null, gameCategory = null, paginateProviders = false) => {
  const [providers, setProviders] = useState([]);
  const [loadingProvider, setLoadingProvider] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState(null);
  const [providerPage, setProviderPage] = useState(0); // Page for provider pagination
  const [providerHasMore, setProviderHasMore] = useState(true); // Flag for provider pagination

  const [categories, setCategories] = useState([]);
  const [loadingCategory, setLoadingCategory] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(null);

  const [types, setTypes] = useState([]);
  const [loadingType, setLoadingType] = useState(false);

  const [games, setGames] = useState([]);
  const [loadingGame, setLoadingGame] = useState(false);
  const [gamePage, setGamePage] = useState(0);
  const [hasMore, setHasMore] = useState(true);
  const [limit] = useState(10);

  // Fetch Providers with conditional pagination
  const fetchProviders = async () => {
    if (loadingProvider) return;
    setLoadingProvider(true);

    let url = `${apiUrl}/game/get-provider-lists`;
    if (paginateProviders) {
      url += `?page=${providerPage}&limit=${limit}`;
      if (gameType) {
        url += `&gameType=${gameType}`;
      }
    } else if (gameType) {
      url += `?gameType=${gameType}`;
    } else{
        url += `?limit=100`;
    }

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        if (paginateProviders) {
          setProviders(prevProviders => [...prevProviders, ...data]);
          if (data.length < limit) {
            setProviderHasMore(false);
          } else {
            setProviderPage(prevPage => prevPage + 1);
          }
        } else {
          setProviders(data);
        }
      }
    } catch (error) {
      console.error('Error fetching providers:', error);
    } finally {
      setLoadingProvider(false);
    }
  };

  // Fetch Categories
  const fetchCategories = async () => {
    if (loadingCategory) return;
    setLoadingCategory(true);

    try {
      const response = await fetch(`${apiUrl}/game/get-category-lists${gameCategory ? `?gameCategory=${gameCategory}` : ''}`);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        setCategories(data);
      }
    } catch (error) {
      console.error('Error fetching categories:', error);
    } finally {
      setLoadingCategory(false);
    }
  };

  // Fetch Types
  const fetchTypes = async () => {
    if (loadingType) return;
    setLoadingType(true);

    try {
      const response = await fetch(`${apiUrl}/game/get-type-lists${gameCategory ? `?gameCategory=${gameCategory}` : ''}`);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        setTypes(data);
      }
    } catch (error) {
      console.error('Error fetching types:', error);
    } finally {
      setLoadingType(false);
    }
  };

  // Fetch Games with Pagination
  const fetchGames = useCallback(async () => {
    if (loadingGame) return;

    setLoadingGame(true);

    const providerQuery = selectedProvider ? `&providerName=${selectedProvider}` : '';
    const categoryQuery = selectedCategory ? `&gameCategory=${selectedCategory}` : '';
    const url = `${apiUrl}/game/get-game-lists?limit=${limit}&page=${gamePage}${providerQuery}${categoryQuery}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (response.ok && Array.isArray(data)) {
        setGames(prevGames => [...prevGames, ...data]);
        setHasMore(data.length === limit);
        setGamePage(prevPage => prevPage + 1);
      } else {
        setHasMore(false);
      }
    } catch (error) {
      console.error('Error fetching games:', error);
      setHasMore(false);
    } finally {
      setLoadingGame(false);
    }
  }, [apiUrl, gamePage, limit, selectedProvider, selectedCategory, loadingGame]);

  const handleProviderClick = (providerName) => {
    resetGames();
    setSelectedCategory(null);
    setSelectedProvider(providerName !== selectedProvider ? providerName : null);
  };

  const handleCategorySelect = (categoryName) => {
    resetGames();
    setSelectedProvider(null);
    setSelectedCategory(categoryName !== selectedCategory ? categoryName : null);
  };

  const resetGames = () => {
    setGames([]);
    setGamePage(0);
    setHasMore(true);
  };

  // Fetch providers, categories, and types when the component mounts
  useEffect(() => {
    fetchProviders();
    fetchCategories();
    fetchTypes();
  }, [gameType, gameCategory]); // Re-fetch when gameType or gameCategory changes

  return {
    providers,
    loadingProvider,
    providerHasMore,
    fetchProviders, // To manually fetch more providers if needed
    selectedProvider,
    handleProviderClick,
    categories,
    loadingCategory,
    selectedCategory,
    handleCategorySelect,
    types,
    loadingType,
    games,
    loadingGame,
    hasMore,
    fetchGames,
    resetGames,
  };
};

export default useGameData;
