import React from 'react';
import { Modal } from 'antd';
import { useModal } from './ModalContext';
import '../pages/PromotionPage.css';
import './RankModal.css'

const RankModal = () => {
  const { modalState, closeModal } = useModal();
  const { isVisible, data } = modalState;

  if (modalState.type !== 'rank') return null;

  return (
    <Modal
      open={isVisible}
      footer={null}
      onCancel={closeModal}
      width={900}
    >
      <div className="custom-rank-modal" style={{backgroundImage: 'url(/rank_background.png)'}}>
        {data && (
          <div>
            <h1>Your Username : {data?.telephoneNumber}</h1>
            <h1>Current Rank : {data?.rank?.currentRank}</h1>
            <p>ระดับในปัจจุบันของท่านคือ {data?.rank?.currentRank} ท่านจะได้รับสิทธิพิเศษดังต่อไปนี้</p>
            <p>1. Commission ต่อยอด Turnover เท่ากับ 1%</p>
            <p>2. โบนัสฝากเงินทุกๆ วันเกิด</p>
            <p>3. ฝาก-ถอน ฟรีวันละ 3 ครั้ง</p>
            <p>4. สิทธิ์ในการเข้าถึงโปรโมชั่นของ Rank {data?.rank?.currentRank}</p>
          </div>
        )}
      </div>
      
    </Modal>
  );
};

export default RankModal;
