import React from 'react';
import './Footer.css'; // Make sure the path is correct

function Footer() {
    return (
        <footer className="footer">
            <p>Copyright © 2024 All Rights Reserved. Winner Sites, Inc.</p>
            <nav>
                <ul className="nav-list">
                    <li className="nav-item"><a href="/terms" className="footer-link">Terms of Service</a></li>
                    <li className="nav-item"><a href="/privacy" className="footer-link">Privacy Policy</a></li>
                </ul>
            </nav>
        </footer>
    );
}

export default Footer;