import React, { createContext, useContext, useState } from 'react';

const ModalContext = createContext();

export const ModalProvider = ({ children }) => {
  const [modalState, setModalState] = useState({
    isVisible: false,
    type: null,
    data: null,
  });

  const openModal = (type, data) => {
    setModalState({
      isVisible: true,
      type,
      data,
    });
  };

  const closeModal = () => {
    setModalState({
      isVisible: false,
      type: null,
      data: null,
    });
  };

  return (
    <ModalContext.Provider value={{ modalState, openModal, closeModal }}>
      {children}
    </ModalContext.Provider>
  );
};

export const useModal = () => {
  const context = useContext(ModalContext);
  if (!context) {
    throw new Error('useModal must be used within a ModalProvider');
  }
  return context;
};
