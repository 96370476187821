import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import './UserDetail.css';
import './Account.css'

function Account(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);

    useEffect(() => {
        if(auth.userData){
            setAccountData(auth.userData);
        }
    }, [auth]);

    useEffect(() => {
        if (accountData) {
            const initialFormValues = {
                username: accountData.telephoneNumber,
                balance: accountData.wallet.balance,
                memberId: accountData.memberId,
            };
    
            if (accountData.mainBankAccount && accountData.mainBankAccount.id !== null) {
                initialFormValues.bankAccountId = accountData.mainBankAccount.id;
            }
        
            form.setFieldsValue(initialFormValues);
            setIsBankAccountAvailable(accountData.bankAccounts.length > 0);
          }
    }, [accountData, form]);

    const changeMainBankAccount = async(values) => {
        console.log('Form values:', JSON.stringify(values));
        const method = "PUT";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/setmain`;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            body: JSON.stringify(values)
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account set successfully!');

        } catch (error) {
            console.error('Error posting member:', error);
        }
    }

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Account</h1>

                <div className="user-detail-data">
                    <Form form={form} 
                        className="account-form" layout="vertical" 
                        onFinish={changeMainBankAccount}
                        initialValues={{
                        enable: true,
                    }}>
                        <Form.Item
                            name="memberId"
                            hidden
                        >
                            <Input/>
                        </Form.Item>

                        <Form.Item name="username" label="Username" rules={[{ required: true }]} dis>
                            <Input disabled={true}/>
                        </Form.Item>

                        <Form.Item name="balance" label="Balance" rules={[{ required: true }]}>
                            <Input disabled={true}/>
                        </Form.Item>

                        <Form.Item name="bankAccountId" label="Default Bank Account" rules={[{ required: true }]}>
                            <Select placeholder="Select Bank Account" disabled={!isBankAccountAvailable}>
                            {isBankAccountAvailable ? (
                                accountData.bankAccounts.map(account => (
                                <Select.Option
                                    key={account.id}
                                    value={account.id}
                                    disabled={account.id === accountData.mainBankAccount.id}
                                >
                                    {account.bankName} - {account.accountNumber} - {account.fullName}
                                </Select.Option>
                                ))
                            ) : (
                                <Select.Option disabled>Loading bank accounts...</Select.Option>
                            )}
                            </Select>
                        </Form.Item>
                        
                        <Form.Item>
                            <Link to="/user/detail/managebank">
                                <Button type="primary" danger>Manage Bank Account</Button>
                            </Link>
                        </Form.Item>

                        <Button type="primary" htmlType="submit">Submit</Button>
                    </Form>
                </div>

                {/* <p style={{color: 'white'}}>Welcome, {auth.username}</p>
                <p style={{color: 'white'}}>You are {auth.isAuthenticated ? "authenticated" : "not authenticated"}.</p> */}
            </div>
        </div>
    );
}

export default Account;