import logo from '../../logo.svg';
import '../../App.css';
import './SlotPage.css';

import Header from '../layout/Header';
import MainCover from '../layout/LottoPage/MainCover'
import LoggedInCover from '../layout/LottoPage/LoggedInCover'
import Footer from '../layout/Footer';

import { useModal } from '../modal/ModalContext';


import React, {createContext, useContext, useEffect, useState, useCallback, useRef, useReducer  } from 'react';
import Login from '../common/Login';
import { AuthProvider, useAuth } from '../../AuthContext';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';

import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import Register from '../common/Register';
import InfiniteScroll from 'react-infinite-scroll-component';
import Account from '../pages/UserDetail/Account';
import DepositStepOne from '../pages/UserDetail/DepositStepOne';
import DepositOnlineTransfer from '../pages/UserDetail/DepositOnlineTransfer';
import DepositPaypay89 from '../pages/UserDetail/DepositPaypay89';
import ManageBankAccount from '../pages/UserDetail/ManageBankAccount';
import AddBankAccount from '../pages/UserDetail/AddBankAccount';
import Withdraw from '../pages/UserDetail/Withdraw';
import InviteFriend from '../pages/UserDetail/InviteFriend';
import TransactionHistory from '../pages/UserDetail/TransactionHistory';
import PromotionHistory from '../pages/UserDetail/PromotionHistory';
import PromotionPage from '../pages/PromotionPage';
import RankModal from '../modal/RankModal';
import WithdrawLocalTransfer from '../pages/UserDetail/WithdrawLocalTransfer';


function LottoPage(){
    const [showLogin, setShowLogin] = useState(false);
    const [showRegister, setShowRegister] = useState(false);
    const { login , auth} = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
  
    const [games, setGames] = useState([]);
    const [page, setPage] = useState(0);
    const [hasMore, setHasMore] = useState(true);
  
    const [providers, setProviders] = useState([]);
    const [selectedProvider, setSelectedProvider] = useState(null);
  
    const [selectedCategory, setSelectedCategory] = useState(null);

    const [selectedType, setSelectedType] = useState(null);

    const [gameTypes, setTypes] = useState([]);
  
    const userDetailWindowRef = useRef(null);
  
    const isFirstRender = useRef(true);
  
    const { openModal } = useModal();
  
    const [searchParams] = useSearchParams();
    
    useEffect(() => {
        fetchGames(); 
        fetchProviders();
        fetchTypes();
    }, []);
  
    useEffect(() => {
        if (isFirstRender.current) {
          isFirstRender.current = false;
          return;
      }
      setGames([]);
      fetchGames(selectedProvider, selectedCategory, selectedType);
    }, [selectedProvider, selectedCategory, selectedType]);
  
    useEffect(() => {
      const fetchAffiliateCode = async () => {
        const affiliateCode = searchParams.get('code');
        if (affiliateCode) {
          let affiliateApiUrl = apiUrl + "/api/affiliate/click?code=" + affiliateCode;
          console.log(affiliateApiUrl);
          Cookies.set('winner_affiliate_code', affiliateCode, { expires: 14, path: '/' });
    
          try {
            const response = await fetch(affiliateApiUrl, {
              method: 'GET',
              headers: {
                'Content-Type': 'application/json',
              }
            });
    
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            console.log(response);
    
          } catch (error) {
            console.error("affiliateCode Click :", error);
          }
        }
      };
    
      fetchAffiliateCode();
    }, [searchParams]);
  
    useEffect(() => {
        console.log("Games updated:", games);
        console.log("Current page:", page);
        console.log("Has more games:", hasMore);
    }, [games, page, hasMore]);
  
    const [loadingGame, setLoadingGame] = useState(false);
    const [loadingProvider, setLoadingProvider] = useState(false);
    const [loadingType, setLoadingType] = useState(false);
    
  
    const fetchGames = useCallback(async (providerName, categoryName, typeName) => {
      if (loadingGame) return;
      setLoadingGame(true);
    
      const providerQuery = providerName ? `&providerName=${providerName}` : '';
      const categoryQuery = categoryName ? `&gameCategory=${categoryName}` : '&gameCategory=KENO';
      const typeQuery = typeName ? `&gameType=${typeName}` : '';
      const url = apiUrl+`/game/get-game-lists?limit=10&page=${page}${providerQuery}${categoryQuery}${typeQuery}`;
    
      try {
        const response = await fetch(url);
        const data = await response.json();
    
        console.log("Fetched URL:", url); // For debugging; remove in production
        if (response.ok && Array.isArray(data)) {
            setGames(prevGames => [...prevGames, ...data]);
            setHasMore(data.length === 10);
            setPage(prevPage => prevPage + 1);
        } else {
            setHasMore(false);
        }
      } catch (error) {
        console.error('Error fetching games:', error);
        setHasMore(false);
      } finally {
        setLoadingGame(false);
      }
    }, [page, loadingGame]);
  
    const fetchProviders = async () => {
      if (loadingProvider) return;
      setLoadingProvider(true);
  
      try {
          const response = await fetch(`https://api.winnermember.net/game/get-provider-lists?limit=100`);
          const data = await response.json();
          
          if (response.ok && Array.isArray(data)) {
              setProviders(data);
          }
      } catch (error) {
          console.error('Error fetching providers:', error);
      } finally {
          setLoadingProvider(false);
      }
    };

    const fetchTypes = async () => {
      if (loadingType) return;
      setLoadingType(true);
  
      try {
          const response = await fetch(apiUrl+`/game/get-type-lists?gameCategory=CASINO`);
          const data = await response.json();

          console.log(data);
          
          if (response.ok && Array.isArray(data)) {
              setTypes(data);
          }
      } catch (error) {
          console.error('Error fetching types:', error);
      } finally {
          setLoadingType(false);
      }
    };
  
    const handleProviderSelect = (providerName) => {
      setPage(0);
      if(providerName !== selectedProvider){
        setSelectedProvider(providerName);
      } else {
        setSelectedProvider(null);
      }
    };
    
    const handleLoginClick = () => {
      setShowLogin(!showLogin);
    };
  
    const handleRegisterClick = () => {
      setShowRegister(!showRegister);
    };
  
    const handleLoginSuccess = (token, username) => {
      login(token, username);
      setShowLogin(false);
    };
  
    const handleOtpSendSuccess = (token) => {
      console.log("OTP Token:", token);
    }
  
    const handleOtpValidate = (code) => {
      console.log("OTP Validate:", code);
    }
  
    const handleRegisterSuccess = (token, username) => {
      login(token, username);
      setShowLogin(false);
      // console.log("Logged in with token:", token);
      // console.log("Logged in with username:", username);
    };
  
    const handleSupportClick = async () => {
  
      if (!auth.token) {
        console.error("No token available");
        return;
      }
      const myHeaders = new Headers();
      
      myHeaders.append("Authorization", `Bearer ${auth.token}`);
      console.log(myHeaders);
  
      const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow"
      };
  
      fetch(apiUrl+"/api/secure-data", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }
  
    const handleHeaderClickSlot = async() => {
  
      // Test Get Agent Credit
      console.log("test get agent Credit");
  
      const requestOptions = {
        method: "GET",
        headers: {
          'Content-Type': 'application/json',
        },
        redirect: "follow"
      };
  
      fetch(apiUrl+"/agent/get-agent-credit", requestOptions)
        .then((response) => response.text())
        .then((result) => console.log(result))
        .catch((error) => console.error(error));
    }
  
    const handleHeaderClickCasino = async() => {
  
      // Test Create User
      console.log("Test Create User");
  
      const raw = JSON.stringify({
          "userName": "0886146932",
          "password" : "Jakr159753",
          "fullName" : "JaxzdaGOD"
      });
  
      try {
        const response = await fetch(apiUrl+'/member/test-create-user', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: raw
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
  
      } catch (error) {
        console.error("Test Create User Request Error :", error);
      }
    }
  
    const handleHeaderClickSport = async() => {
      // Test Deposit Money to User
      console.log("Test Deposit Money to User");
  
      const raw = JSON.stringify({
          "userName": "winpro0886146932",
          "balance" : 100,
          "boolean" : true
      });
  
      try {
        const response = await fetch(apiUrl+'/member/test-deposit-money', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: raw
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
  
      } catch (error) {
        console.error("Test Deposit Money to User Request Error :", error);
      }
    }
  
    const handleHeaderClickPromotion = async() => {
      // Test Start Game
      console.log("Test Start Game");
  
      const raw = JSON.stringify({
          "username": "winpro0886146932",
          "gameID" : "62c408e21bf3465ade92add7",
          "provider" : "POPG",
          "redirectUrl": "https://google.com",
          "language" : "th",
          "tab" : "Slot",
          "ip": "127.0.0.1"
      });
  
      try {
        const response = await fetch(apiUrl+'/member/test-start-game', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: raw
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
  
      } catch (error) {
        console.error("Test Start Game Request Error :", error);
      }
    }
  
    const handleMemberRankClick = () => {
      openModal('rank', auth.userData);
    }
  
    const handleHeaderClickInviteFriend = async() => {
      
    }
  
    const loginCloseClicked = () => {
      setShowLogin(false);
    }
  
    const registerCloseClicked = () => {
      setShowRegister(false);
    }
  
    const handleCategorySelect = (categoryName) => {
      setPage(0);
      setSelectedCategory(categoryName);
    };

    const handleTypeSelect = (typeName) => {
      setPage(0);
      setSelectedType(typeName);
      console.log("handleTypeSelect");
    };
  
    const handleUserDetail = (event) => {
      event.preventDefault(); // Prevent the default link behavior
  
      // Check if the window already exists and is not closed
      if (userDetailWindowRef.current && !userDetailWindowRef.current.closed) {
        userDetailWindowRef.current.focus(); // Focus on the existing window
        userDetailWindowRef.current.location.reload();
        return;
      }
  
      userDetailWindowRef.current = window.open(
        '',
        '_blank',
        'width=600,height=700,toolbar=no,scrollbars=yes,resizable=yes'
      );
  
      const syncAuthContext = () => {
        userDetailWindowRef.current.localStorage.setItem('winner-jwt', auth.token);
        userDetailWindowRef.current.localStorage.setItem('username', auth.username);
        userDetailWindowRef.current.React = React; // Pass React to the new window
        userDetailWindowRef.current.ReactDOM = require('react-dom'); // Pass ReactDOM to the new window
        userDetailWindowRef.current.history.pushState({}, '', '/user/detail');
      };
  
      userDetailWindowRef.current.onload = syncAuthContext;
      userDetailWindowRef.current.location.href = `${window.location.origin}/user/detail/account`;
    };
  
    function openNewTab(url) {
      window.open(url, '_blank');
    }
  
    const handlePlay = async(gameUniqueId) => {
      // Log the uniqueId or make a request
      console.log("Playing game with ID:", gameUniqueId);
  
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");
  
      const raw = JSON.stringify({
        "username": auth.username,
        "gameID": gameUniqueId,
        "language": "th",
        "device" : "desktop",
        "ip": "127.0.0.1"
      });
  
      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow"
      };
  
      try {
        const response = await fetch(apiUrl+'/member/test-start-game', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: raw
        });
  
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
  
        const data = await response.json();
        console.log(data);
        openNewTab(data.data.uri);
        
      } catch (error) {
        console.error("Login failed:", error);
      }
    }
  
    return (
      <>
        <Header onLoginClick={handleLoginClick} onRegisterClick={handleRegisterClick} 
                onSupportClick={handleSupportClick} onUserDetail={handleUserDetail}
                onMemberRank={handleMemberRankClick}
        />
          {showLogin && <Login onLoginSuccess={handleLoginSuccess} onCloseClicked={loginCloseClicked}/>}
          {showRegister && <Register onCloseClicked={registerCloseClicked}/>}

          {auth.isAuthenticated ? (
            <LoggedInCover />
            ) : (
            <MainCover onRegisterClick={handleRegisterClick}/>
            )
          }
  
          <div className="layout" style={{backgroundImage: 'url(/background_3_pages.png)', backgroundSize: 'cover'}}>
            
            <main className="main-content">
              <h1 style={{color: '#DAA520'}}>Select Lotto/Keno Games</h1>

              <div className="flex-container">
              <InfiniteScroll
                  className="flex-container"
                  dataLength={games.length}
                  next={() => fetchGames(selectedProvider, selectedCategory, selectedProvider)}
                  hasMore={hasMore}
                  loader={<h4>Loading...</h4>}
              >
                  {games.map((game, index) => (
                    <div className="flex-item" key={game.uniqueId}>
                      <img style={{borderRadius:'20'}} src={game.image.verticalUrl} alt={game.name}/>
                      <div className="button-container">
                        <button className="button" onClick={() => handlePlay(game.uniqueId)}>Play</button>
                      </div>
                    </div>
                  ))}
              </InfiniteScroll>
              </div>
  
            </main>
  
            <RankModal/>
          </div>
        <Footer />
      </>
    );
}

export default LottoPage;