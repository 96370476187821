import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import { useLocation } from 'react-router-dom';
import Header from '../../layout/UserDetail/Header';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import './UserDetail.css';

function DepositFailed(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const tid = query.get('tid');
    const status = query.get('status');

    useEffect(() => {
        if (tid && status) {
          // Example of sending data to the backend
          fetch(`${apiUrl}/paypay89/deposit/failed`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              Authorization: `Bearer ${auth.token}`,
            },
            body: JSON.stringify({ tid, status }),
          })
            .then(response => response.json())
            .then(data => {
              // Handle response data
              console.log(data);
            })
            .catch(error => {
              // Handle errors
              console.error('Error:', error);
            });
        }
      }, []);

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src="/transaction_failed.png" style={{borderRadius: '10px', width: '500px', height: '600px'}}></img>
        </div>
  );
}

export default DepositFailed;