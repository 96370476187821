import React from 'react';
import ReactDOM from 'react-dom/client';
import { AuthProvider } from './AuthContext';
import App from './App';
import { ModalProvider } from './components/modal/ModalContext';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
    <AuthProvider>
        <ModalProvider>
            <App/>
        </ModalProvider>
    </AuthProvider>
);
