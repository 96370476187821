import React, { useState } from 'react';
import './Register.css';
import Cookies from 'js-cookie';

function Register({ onCloseClicked}) {
    const [step, setStep] = useState(1);
    const [phoneNumber, setPhoneNumber] = useState('');
    const [otp, setOtp] = useState('');
    const [otpToken, setOtpToken] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const apiUrl = process.env.REACT_APP_API_URL;
    const isOtpEnable = false;

    const handlePhoneNumberSubmit = async(e) => {

        if(isOtpEnable){
          e.preventDefault();
      
          const raw = JSON.stringify({
              "telephoneNumber": phoneNumber,
          });
      
          try {
            const response = await fetch(apiUrl+'/api/register/otp/request', {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
              },
              body: raw
            });
      
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
            const data = await response.json();
            setOtpToken(data.token);
            if(data.token != null){
              setStep(2);
            }
            console.log(data);

          } catch (error) {
            console.error("OTP Request Error :", error);
          }
        }
        else{
          setStep(3);
        }
    };

    const resendOtp = async(e) => {
        e.preventDefault();
    
        const raw = JSON.stringify({
            "telephoneNumber": phoneNumber,
        });
    
        try {
          const response = await fetch(apiUrl+'/api/register/otp/request', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: raw
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          setOtpToken(data.token);
          if(data.token != null){
            setStep(2);
          }
          console.log(data);

        } catch (error) {
          console.error("OTP Request Error :", error);
        }
    };
    
    const handleOtpSubmit = async(e) => {
        e.preventDefault();
        const raw = JSON.stringify({
            "token": otpToken,
            "otpCode": otp
        });
    
        try {
          const response = await fetch(apiUrl+'/api/register/otp/validate', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: raw
          });
    
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
          const data = await response.json();
          console.log(data);

          if(data.code == "000"){
            setStep(3);
          }
          
        } catch (error) {
          console.error("OTP Request Error :", error);
        }

        console.log('OTP:', otp);
        
    };

    const handlePasswordSubmit = async(e) => {
        e.preventDefault();
        // Here you would handle registration completion
        if (password === confirmPassword) {
            console.log('Passwords match, proceed with registration.');

            const affiliateCode = Cookies.get('winner_affiliate_code') || '';

            const raw = JSON.stringify({
                "telephoneNumber": phoneNumber,
                "password": password,
                "affiliateCode": affiliateCode
            });
        
            try {
                const response = await fetch(apiUrl+'/api/register/store', {
                    method: 'POST',
                    headers: {
                    'Content-Type': 'application/json',
                    },
                    body: raw
                });
            
                if (!response.ok) {
                    throw new Error(`HTTP error! status: ${response.status}`);
                }

                const data = await response.json();
                console.log(data);

                if(response.ok){
                    setStep(4);
                }
            
            } catch (error) {
                console.error("handlePasswordSubmit :", error);
            }
        } else {
            alert('Passwords do not match!');
        }
    };

    const closeClicked = () => {
        onCloseClicked();
    }

    return (
        <div className="register-container" style={{backgroundImage: 'url(/background_3_pages.png)', backgroundSize: 'cover'}}>
        <button className="closeButton" onClick={closeClicked}>
            X
        </button>
        {step === 1 && (
            <form onSubmit={handlePhoneNumberSubmit}>
                <label>Enter Phone Number to Register</label>
                <input
                    type="text"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    placeholder="Enter your phone number"
                />
                <button type="submit">Register</button>
            </form>
        )}

        {step === 2 && (
            <form onSubmit={handleOtpSubmit}>
                <label>Enter OTP you got from SMS</label>
                <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    placeholder="Enter OTP"
                />
                <button type="submit">Verify OTP</button>
                <div className="resend-button" onClick={resendOtp}><a>Resend</a></div>
            </form>
            
        )}

        {step === 3 && (
            <form onSubmit={handlePasswordSubmit}>
                <label>Please Enter Password</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    placeholder="Enter Password"
                />
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    placeholder="Confirm Password"
                />
                <button type="submit">Register</button>
            </form>
        )}

        {step === 4 && (
            <h1 className="register-success">Register Success ! You can close.</h1>
        )}
        </div>
    );
}

export default Register;