import React from 'react';
import { Link } from 'react-router-dom';

function Header(){
    return (
        <div className="user-detail-header">
            <img className="nav-logo" src="/header_logo.png"></img>

            <ul className="user-detail-nav-list">
            <li className="user-detail-nav-item">
                <Link to="/user/detail/account" className="user-detail-nav-link">บัญชี</Link>
            </li>
            <li className="user-detail-nav-item">
                <Link to="/user/detail/deposit" className="user-detail-nav-link">ฝากเงิน</Link>
            </li>
            <li className="user-detail-nav-item">
                <Link to="/user/detail/withdraw" className="user-detail-nav-link">ถอนเงิน</Link>
            </li>
            <li className="user-detail-nav-item">
                <Link to="/user/detail/transaction/history" className="user-detail-nav-link">ประวัติธุรกรรม</Link>
            </li>
            <li className="user-detail-nav-item">
                <Link to="/user/detail/promotion/history" className="user-detail-nav-link">ประวัติโปรโมชั่น</Link>
            </li>
            <li className="user-detail-nav-item">
                <Link to="/user/detail/invitefriend" className="user-detail-nav-link">เชิญเพื่อน</Link>
            </li>
            </ul>

        </div>
  );
}

export default Header;