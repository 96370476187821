import logo from './logo.svg';
import './App.css';

import useFetchGames from './useFetchGames';

import Header from './components/layout/Header';
import MainSideBar from './components/layout/MainSideBar';
import MainCover from './components/layout/MainPage/MainCover'
import LoggedInCover from './components/layout/MainPage/LoggedInCover'
import Footer from './components/layout/Footer';

import { useModal } from './components/modal/ModalContext';


import React, {createContext, useContext, useEffect, useState, useCallback, useRef, useReducer  } from 'react';
import Login from './components/common/Login';
import { AuthProvider, useAuth } from './AuthContext';
import { BrowserRouter as Router, Route, Routes, Link, useLocation } from 'react-router-dom';

import { useSearchParams } from 'react-router-dom';
import Cookies from 'js-cookie';

import Register from './components/common/Register';
import InfiniteScroll from 'react-infinite-scroll-component';
import Account from './components/pages/UserDetail/Account'
import DepositStepOne from './components/pages/UserDetail/DepositStepOne';
import DepositOnlineTransfer from './components/pages/UserDetail/DepositOnlineTransfer';
import DepositPaypay89 from './components/pages/UserDetail/DepositPaypay89';
import ManageBankAccount from './components/pages/UserDetail/ManageBankAccount';
import EditBankAccount from './components/pages/UserDetail/EditBankAccount';
import AddBankAccount from './components/pages/UserDetail/AddBankAccount';
import Withdraw from './components/pages/UserDetail/Withdraw';
import InviteFriend from './components/pages/UserDetail/InviteFriend';
import TransactionHistory from './components/pages/UserDetail/TransactionHistory';
import PromotionHistory from './components/pages/UserDetail/PromotionHistory';
import DepositSuccess from './components/pages/UserDetail/DepositSuccess';
import DepositFailed from './components/pages/UserDetail/DepositFailed';

import RankModal from './components/modal/RankModal';
import WithdrawLocalTransfer from './components/pages/UserDetail/WithdrawLocalTransfer';


import SlotPage from './components/pages/SlotPage';
import CasinoPage from './components/pages/CasinoPage';
import SportPage from './components/pages/SportPage';
import EsportPage from './components/pages/EsportPage';
import LotteryPage from './components/pages/LotteryPage';
import PromotionPage from './components/pages/PromotionPage';
import InviteFriendPage from './components/pages/InviteFriendPage';

import { AnimatePresence, motion } from 'framer-motion';

import useGameData from './useGameData';

function MainApp(){
  const [showLogin, setShowLogin] = useState(false);
  const [showRegister, setShowRegister] = useState(false);
  const { login , auth} = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;

  const userDetailWindowRef = useRef(null);

  const isFirstRender = useRef(true);

  const { openModal } = useModal();

  const [searchParams] = useSearchParams();

  const {
    providers,
    loadingProvider,
    providerHasMore,
    fetchProviders,
    selectedProvider,
    handleProviderClick,
    categories,
    loadingCategory,
    selectedCategory,
    handleCategorySelect,
    types,
    loadingType,
    games,
    loadingGame,
    hasMore,
    fetchGames,
  } = useGameData(apiUrl, null, null, false);
  
  useEffect(() => {
      fetchGames(); 
  }, []);

  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
      return;
    }
    fetchGames(selectedProvider, selectedCategory);
  }, [selectedProvider, selectedCategory]);

  useEffect(() => {
    const fetchAffiliateCode = async () => {
      const affiliateCode = searchParams.get('code');
      if (affiliateCode) {
        let affiliateApiUrl = apiUrl + "/api/affiliate/click?code=" + affiliateCode;
        Cookies.set('winner_affiliate_code', affiliateCode, { expires: 14, path: '/' });
  
        try {
          const response = await fetch(affiliateApiUrl, {
            method: 'GET',
            headers: {
              'Content-Type': 'application/json',
            }
          });
  
          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }
  
          // console.log(response);
  
        } catch (error) {
          console.error("affiliateCode Click :", error);
        }
      }
    };
  
    fetchAffiliateCode();
  }, [searchParams]);
  
  const handleLoginClick = () => {
    setShowLogin(!showLogin);
  };

  const handleRegisterClick = () => {
    setShowRegister(!showRegister);
  };

  const handleLoginSuccess = (token, username) => {
    login(token, username);
    setShowLogin(false);
  };

  const handleOtpSendSuccess = (token) => {
    // console.log("OTP Token:", token);
  };

  const handleOtpValidate = (code) => {
    // console.log("OTP Validate:", code);
  };

  const handleRegisterSuccess = (token, username) => {
    login(token, username);
    setShowLogin(false);
    // console.log("Logged in with token:", token);
    // console.log("Logged in with username:", username);
  };

  const handleSupportClick = async () => {

    if (!auth.token) {
      console.error("No token available");
      return;
    }
    const myHeaders = new Headers();
    
    myHeaders.append("Authorization", `Bearer ${auth.token}`);
    // console.log(myHeaders);

    const requestOptions = {
      method: "GET",
      headers: myHeaders,
      redirect: "follow"
    };

    fetch(apiUrl+"/api/secure-data", requestOptions)
      .then((response) => response.text())
      .then((result) => console.log(result))
      .catch((error) => console.error(error));
  };

  const handleMemberRankClick = () => {
    openModal('rank', auth.userData);
  };

  const loginCloseClicked = () => {
    setShowLogin(false);
  };

  const registerCloseClicked = () => {
    setShowRegister(false);
  };

  const handleUserDetail = (event) => {
    event.preventDefault(); // Prevent the default link behavior

    // Check if the window already exists and is not closed
    if (userDetailWindowRef.current && !userDetailWindowRef.current.closed) {
      userDetailWindowRef.current.focus(); // Focus on the existing window
      userDetailWindowRef.current.location.reload();
      return;
    }

    userDetailWindowRef.current = window.open(
      '',
      '_blank',
      'width=600,height=700,toolbar=no,scrollbars=yes,resizable=yes'
    );

    const syncAuthContext = () => {
      userDetailWindowRef.current.localStorage.setItem('winner-jwt', auth.token);
      userDetailWindowRef.current.localStorage.setItem('username', auth.username);
      userDetailWindowRef.current.React = React; // Pass React to the new window
      userDetailWindowRef.current.ReactDOM = require('react-dom'); // Pass ReactDOM to the new window
      userDetailWindowRef.current.history.pushState({}, '', '/user/detail');
    };

    userDetailWindowRef.current.onload = syncAuthContext;
    userDetailWindowRef.current.location.href = `${window.location.origin}/user/detail/account`;
  };

  function openNewTab(url) {
    window.open(url, '_blank');
  };

  const handlePlay = async(gameUniqueId) => {
    // Log the uniqueId or make a request
    // console.log("Playing game with ID:", gameUniqueId)
    const raw = JSON.stringify({
      "username": auth.username,
      "gameID": gameUniqueId,
      "language": "th",
      "device" : "desktop",
      "ip": "127.0.0.1"
    });

    const requestOptions = {
      method: "POST",
      headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
      body: raw,
      redirect: "follow"
    };

    try {
      const response = await fetch(apiUrl+'/member/startgame', requestOptions);

      

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const data = await response.json();

      // Check if data.data and data.data.uri exist
      if (data.data && data.data.uri) {
        console.log("Opening new tab with URI:", data.data.uri);
        openNewTab(data.data.uri);
      } else {
        console.error("URI not found in response data:", data);
      }
      
    } catch (error) {
      console.error("Login failed:", error);
    }
  };

  return (
    <>
      <Header onLoginClick={handleLoginClick} onRegisterClick={handleRegisterClick} 
              onSupportClick={handleSupportClick} onUserDetail={handleUserDetail}
              onMemberRank={handleMemberRankClick}
      />
        {showLogin && <Login onLoginSuccess={handleLoginSuccess} onCloseClicked={loginCloseClicked}/>}
        {showRegister && <Register onCloseClicked={registerCloseClicked}/>}

        {auth.isAuthenticated ? (
          <LoggedInCover />
        ) : (
          <MainCover onRegisterClick={handleRegisterClick}/>
        )}
      

        <div className="layout" style={{backgroundImage: 'url(/background_3_pages.png)', backgroundSize: 'cover'}}>
          
          <MainSideBar onCategorySelect={handleCategorySelect}/>
          
          <main className="main-content">
            <h1 style={{color: '#DAA520'}}>Provider</h1>
            <div className="provider-container">
              {providers.map((provider, index) => (
                <img className={`provider-button ${selectedProvider === provider.name ? "selected" : ""}`} 
                     key={provider.id}  src={provider.logoUrl} 
                     onClick={() => handleProviderClick(provider.name)} 
                     alt={provider.name}>
                </img>
              ))}
            </div>
            
            <div className="flex-container">
            <InfiniteScroll
                className="flex-container"
                dataLength={games.length}
                next={() => fetchGames(selectedProvider, selectedCategory)}
                hasMore={hasMore}
                loader={<h4>Loading...</h4>}
            >
                {games.map((game, index) => (
                  <div className="flex-item" key={game.uniqueId}>
                    <img src={game.image.verticalUrl} alt={game.name}/>
                    <div className="button-container">
                      <button className="button" onClick={() => handlePlay(game.uniqueId)}>Play</button>
                      {/* <button className="button">Demo</button> */}
                    </div>
                  </div>
                ))}
            </InfiniteScroll>
            </div>

          </main>

          <RankModal/>
        </div>
      <Footer />
    </>
  );
}

const fadeScaleVariants = {
  initial: {
    opacity: 0,
    scale: 0.95,
  },
  in: {
    opacity: 1,
    scale: 1,
  },
  out: {
    opacity: 0,
    scale: 0.95,
  },
};

const fadeScaleTransition = {
  type: 'tween',
  ease: 'anticipate',
  duration: 0.2,
};

const PageWrapper = ({ children }) => {
  return children;
  // return (
    // <motion.div
    //   style={{ backgroundColor: 'black' }}
    //   initial="initial"
    //   animate="in"
    //   exit="out"
    //   variants={fadeScaleVariants}
    //   transition={fadeScaleTransition}
    // >
    //   {children}
    // </motion.div>
  // );
};

const AppRoutes = () => {
  const location = useLocation();
  
  return (
    <AnimatePresence mode="wait">
      <Routes location={location} key={location.pathname}>
        <Route path="/" element={<MainApp />} />
        <Route path="/slot" element={<PageWrapper><SlotPage /></PageWrapper>} />
        <Route path="/casino" element={<PageWrapper><CasinoPage /></PageWrapper>} />
        <Route path="/sport" element={<PageWrapper><SportPage /></PageWrapper>} />
        <Route path="/esport" element={<PageWrapper><EsportPage /></PageWrapper>} />
        <Route path="/lottery" element={<PageWrapper><LotteryPage /></PageWrapper>} />
        <Route path="/promotion" element={<PageWrapper><PromotionPage /></PageWrapper>} />
        <Route path="/invitefriend" element={<PageWrapper><InviteFriendPage /></PageWrapper>} />
        <Route path="/user/detail/account" element={<PageWrapper><Account /></PageWrapper>} />
        <Route path="/user/detail/managebank" element={<PageWrapper><ManageBankAccount /></PageWrapper>} />
        <Route path="/user/detail/managebank/add" element={<PageWrapper><AddBankAccount /></PageWrapper>} />
        <Route path="/user/detail/managebank/edit/:id" element={<PageWrapper><EditBankAccount /></PageWrapper>} />
        <Route path="/user/detail/deposit" element={<PageWrapper><DepositStepOne /></PageWrapper>} />
        <Route path="/user/detail/deposit/success" element={<PageWrapper><DepositSuccess /></PageWrapper>} />
        <Route path="/user/detail/deposit/failed" element={<PageWrapper><DepositFailed /></PageWrapper>} />
        <Route path="/user/detail/deposit/onlinetransfer" element={<PageWrapper><DepositOnlineTransfer /></PageWrapper>} />
        <Route path="/user/detail/deposit/paypay89" element={<PageWrapper><DepositPaypay89 /></PageWrapper>} />
        <Route path="/user/detail/withdraw" element={<PageWrapper><Withdraw /></PageWrapper>} />
        <Route path="/user/detail/withdraw/localbanktransfer" element={<PageWrapper><WithdrawLocalTransfer /></PageWrapper>} />
        <Route path="/user/detail/transaction/history" element={<PageWrapper><TransactionHistory /></PageWrapper>} />
        <Route path="/user/detail/promotion/history" element={<PageWrapper><PromotionHistory /></PageWrapper>} />
        <Route path="/user/detail/invitefriend" element={<PageWrapper><InviteFriend /></PageWrapper>} />
      </Routes>
    </AnimatePresence>
  );
};

function App() {
  return (
    <AuthProvider>
      <Router>
        <AppRoutes />
      </Router>
    </AuthProvider>
  );
}

export default App;
