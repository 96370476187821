import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import DataTable from '../../table/DataTable';
import './UserDetail.css';
import { format, parseISO, addHours } from 'date-fns';

function TransactionHistory(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const [page, setPage] = useState(0); // Track the current page
    const [size] = useState(5); // Track the page size
    const [totalPages, setTotalPages] = useState(0); // Track the total number of pages

    const location = useLocation();
    const { filterType } = location.state || {};

    const [activeTab, setActiveTab] = useState('all');


    const columns = useMemo(() => [
        { Header: 'ประเภท', accessor: 'type' },
        {
            Header: 'จำนวนเงิน',
            accessor: 'amount',
            Cell: ({ value }) => {
                // Ensure the value is a number and format it to 2 decimal places
                return Number(value).toFixed(2);
            }
        },
        { Header: 'สถานะ', accessor: 'status' },
        {
            Header: 'วันที่ทำธุรกรรม',
            accessor: 'createdAt',
            Cell: ({ value }) => {
                // Parse the ISO date string and add 7 hours to convert to UTC+7
                const date = parseISO(value);
                const dateInUTCPlus7 = addHours(date, 7);
                // Format the date as desired
                return format(dateInUTCPlus7, 'yyyy-MM-dd HH:mm:ss');
            }
        }
    ], []);

    const fetchAccount = async(username) => {
        try {
          const response = await fetch(apiUrl+'/member/getuser?username='+username, {
            method: 'GET',
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setAccountData(data);
          setIsBankAccountAvailable(!!accountData.bankAccount);
          console.log(data);
        } catch (error) {
          console.error("Get User DATA failed:", error);
        }
    };

    const fetchTransactions = async (type, page = 0) => {

        setActiveTab(type);

        try {
            let url = `${apiUrl}/member/transaction/get?memberID=${auth.userData.memberId}&page=${page}&size=${size}`;
    
            // Only add the type to the URL if it's not 'all'
            if (type && type !== 'all') {
                url += `&type=${type}`;
            }

            console.log(url);
    
            const response = await fetch(url, {
                method: 'GET',
                headers: {
                    "Authorization": `Bearer ${auth.token}`,
                    "Content-Type": "application/json"
                },
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
    
            const data = await response.json();
            setData(data.content);
            setTotalPages(data.totalPages);
            setPage(page);
            console.log(data);
    
        } catch (error) {
            console.error("Get User DATA failed:", error);
        }
    }

    const handleNextPage = () => {
        if (page < totalPages - 1) {
            setPage(page + 1);
            fetchTransactions(activeTab, page + 1, size);
        }
    };
    
    const handlePreviousPage = () => {
        if (page > 0) {
            setPage(page - 1);
            fetchTransactions(activeTab, page - 1, size);
        }
    };

    useEffect(() => {
        if (auth.userData) {
            if (filterType) {
                fetchTransactions(filterType);
            } else {
                fetchTransactions('all');
            }
        }
    }, [auth, filterType]);

    const handleEdit = (id) => {
        navigate(`/user/detail/managebank/edit/${id}`);
    };

    const handleDelete = async(id) => {    

        const method = "DELETE";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/delete/`+id;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account deleted successfully!');
            await fetchAccount(auth.username);
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">
                <h1 style={{color: 'white'}}>ประวัติการทำธุรกรรม</h1>
                <div className="user-detail-data">
                    <div className="profile-header">
                        <a className={`profile-header-button ${activeTab === 'all' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('all')}>ทั้งหมด</a>
                        <a className={`profile-header-button ${activeTab === 'deposit' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('deposit')}>ฝากเงิน</a>
                        <a className={`profile-header-button ${activeTab === 'withdraw' ? 'profile-header-button-active' : ''}`} 
                            onClick={() => fetchTransactions('withdraw')}>ถอนเงิน</a>
                    </div>
                    <Form
                        form={form}
                        className="transaction-form"
                        layout="vertical"
                        initialValues={{ enable: true }}
                    >
                        <DataTable columns={columns} data={data} />
                        <div className="pagination-controls">
                            <button onClick={handlePreviousPage} disabled={page === 0}>Prev</button>
                            <span>Page {page + 1} of {totalPages}</span>
                            <button onClick={handleNextPage} disabled={page === totalPages - 1}>Next</button>
                        </div>
                        <br></br>
                    </Form>
                </div>
            </div>
        </div>
  );
}

export default TransactionHistory;