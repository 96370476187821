import React, { useState, useEffect } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import './UserDetail.css';
import { useNavigate } from 'react-router-dom';

function WithdrawLocalTransfer(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (auth.userData) {
            setAccountData(auth.userData);
            const initialFormValues = {
                username: auth.userData.telephoneNumber,
                balance: auth.userData.wallet.balance,
                memberId: auth.userData.memberId,
            };

            if (auth.userData.mainBankAccount && auth.userData.mainBankAccount.id !== null) {
                initialFormValues.bankAccountId = auth.userData.mainBankAccount.id;
            }
        
            form.setFieldsValue(initialFormValues);
            setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
        }
    }, [auth]);


    const onFinish = async(values) => {
        console.log('Received values:', values);

        if (!auth.token) {
            console.error("No token available");
            return;
        }

        const myHeaders = new Headers();
          myHeaders.append("Authorization", `Bearer ${auth.token}`);

        const requestOptions = {
            method: "GET",
            headers: myHeaders,
            redirect: "follow"
        };

        try {
            const response = await fetch(apiUrl + "/member/withdraw/request?username=" + auth.username+"&amount="+values.amount, requestOptions);
            if (response.ok) {
                const data = await response.json();
                navigate('/user/detail/transaction/history', { state: { filterType: 'withdraw' } });
            } else {
                console.error("Failed to fetch URL");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Withdraw Local Bank Transfer</h1>

                <div className="user-detail-data">
                    
                    <Form form={form} 
                        onFinish={onFinish}
                        className="deposit-form" layout="vertical" 
                        initialValues={{
                        enable: true
                    }}>
                        <h1>โปรดระบุจำนวนเงิน</h1>
                        <Form.Item name="amount" label="Balance" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="bankAccountId" label="Default Bank Account" rules={[{ required: true }]}>
                            <Select placeholder="Select Bank Account" disabled={!isBankAccountAvailable}>
                            {isBankAccountAvailable ? (
                                accountData.bankAccounts.map(account => (
                                <Select.Option
                                    key={account.id}
                                    value={account.id}
                                    disabled={account.id === accountData.mainBankAccount.id}
                                >
                                    {account.bankName} - {account.accountNumber} - {account.fullName}
                                </Select.Option>
                                ))
                            ) : (
                                <Select.Option disabled>Loading bank accounts...</Select.Option>
                            )}
                            </Select>
                        </Form.Item>

                        <Form.Item style={{justifySelf: 'center', alignSelf: 'center'}}>
                            <Button type="primary" className="deposit-button" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default WithdrawLocalTransfer;