import React, { useState, useEffect } from 'react';
import './MainSideBar.css';
import useGameData from '../../useGameData';

function MainSideBar({onCategorySelect}) {

    const [gameCategories, setGameCategories] = useState([]);
    const apiUrl = process.env.REACT_APP_API_URL;

    const {
        providers,
        loadingProvider,
        selectedProvider,
        handleProviderClick,
        categories,
        loadingCategory,
        selectedCategory,
        handleCategorySelect,
        games,
        loadingGame,
        hasMore,
        fetchGames,
      } = useGameData(apiUrl);

    const handleCategoryClick = (category) => {
        onCategorySelect(category.name);
    };

    return (
        <aside className="sidebar">
            <ul>
                {categories.map((category, index) => (
                <li key={category.id}>
                    <button onClick={() => handleCategoryClick(category)} 
                    className={`category-button ${selectedCategory === category.name ? "selected" : ""}`}
                    style={{backgroundImage: 'url(button_box.png)', backgroundSize: 'cover'}} 
                    key={category.id}>
                        {category.name}
                    </button>
                </li>
              ))}
                
                {/* <li><button style={{ height: '80px', width: '260px', borderRadius: '24px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/new_game_button.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button1.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button2.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button3.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button4.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button5.png)', backgroundSize: 'cover'}}></button></li>
                <li><button style={{ height: '80px', width: '260px', borderRadius: '16px', backgroundColor: '#DDDDDD', backgroundImage: 'url(/Button6.png)', backgroundSize: 'cover'}}></button></li> */}
            </ul>
        </aside>
    );
}

export default MainSideBar;