import React, { useEffect } from 'react';
import { useAuth } from '../../../AuthContext';
import { useLocation } from 'react-router-dom';
import './UserDetail.css';

function DepositSuccess(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const location = useLocation();

    const query = new URLSearchParams(location.search);
    const tid = query.get('tid');
    const status = query.get('status');

    useEffect(async() => {
        if (tid && status) {
          try {
            const response = await fetch(`${apiUrl}/paypay89/deposit/success`, {
              method: 'POST',
              headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
              body: JSON.stringify({ tid, status }),
            });
  
            if (!response.ok) {
              throw new Error(`HTTP error! status: ${response.status}`);
            }
  
            const data = await response.json();
            console.log(data);
            
          } catch (error) {
            console.error("Get User DATA failed:", error);
          }
        }
      }, []);

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
            <img src="/transaction_completed.png" style={{borderRadius: '10px', width: '500px', height: '600px'}}></img>
        </div>
  );
}

export default DepositSuccess;