import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import './UserDetail.css';

function Withdraw(){

    const { auth } = useAuth();

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Withdraw</h1>

                <div className="user-detail-data">
                    <h1>Select Withdraw Method</h1>
                    <div style={{display: 'flex'}}>
                        <Link to="/user/detail/withdraw/localbanktransfer" className="deposit-method-button">
                            Local Bank Transfer
                        </Link>
                    </div>
                </div>
            </div>
        </div>
  );
}

export default Withdraw;