import React, { useState } from 'react';

function LoggedInCover() {
    return (
        <div style={{ height: '50vh', backgroundImage: 'url(/SlotPageCover.png)', backgroundSize: 'cover', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
          <div className='slot-cover' style={{marginLeft: '600px', width: '600px'}}>
            <h1 style={{color: '#DAA520', fontWeight: '600'}}>เจ้าแรกในประเทศไทย มีมากกว่า แตกง่ายกว่า เล่นได้ทุกระบบ ลื่นไหล เงินเร็ว</h1>
            <h3 style={{color: '#CD7F32', fontWeight: '500'}}>เจ้าแรกในประเทศไทย มีมากกว่า แตกง่ายกว่า เล่นได้ทุกระบบ ลื่นไหล เงินเร็ว</h3>
            <h1 style={{color: '#DAA520', fontWeight: '600'}}>ยินดีต้อนรับ</h1>
            </div>
        </div>
    );
}

export default LoggedInCover;