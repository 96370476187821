import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import './Header.css';
import {
    UserOutlined,
    VideoCameraOutlined,
    UploadOutlined,
  } from '@ant-design/icons';

import { useAuth } from '../../AuthContext';

function Header({ onLoginClick, onRegisterClick, onUserDetail, onSupportClick, onMemberRank}) {

    const { auth, logout } = useAuth();

    const getRankImage = (rank) => {
        switch(rank) {
          case 'bronze':
            return '/rank_bronze.png';
          case 'silver':
            return '/rank_silver.png';
          case 'gold':
            return '/rank_gold.png';
          case 'platinum':
            return '/rank_platinum.png';
        }
      };

    return (
        <header className="header">

            <nav>
                <ul className="nav-list">
                    <img src="/header_bar.png"></img>
                    
                    <li className="nav-item"><Link to="/" className="header-link"><img className="nav-logo" src="/header_logo.png"></img></Link></li>
                    <li className="nav-item"><Link to="/slot" className="header-link">Slot</Link></li>
                    <li className="nav-item"><Link to="/casino" className="header-link">คาสิโน</Link></li>
                    <li className="nav-item"><Link to="/sport" className="header-link">สปอร์ต</Link></li>
                    <li className="nav-item"><Link to="/esport" className="header-link">อีสปอร์ต</Link></li>
                    <li className="nav-item"><Link to="/lottery" className="header-link">หวย</Link></li>
                    <li className="nav-item"><Link to="/promotion" className="header-link">โปรโมชั่น</Link></li>
                    <li className="nav-item"><Link to="/invitefriend" className="header-link">เชิญเพื่อน</Link></li>
                </ul>
            </nav>

            <nav>
                <ul className="nav-list">
                    
                    <li className="nav-line">
                        <img src="/line_logo.png"></img>
                        <Link to="#" className="nav-line-li" onClick={onSupportClick} >Support</Link>
                    </li>

                    {auth.isAuthenticated ? (
                        (
                        <>
                            <Link to="#" onClick={onMemberRank} className="nav-user-img-li">
                                <img src={getRankImage(auth.userData?.rank?.currentRank)} className="nav-user-img"></img>
                            </Link>

                            <Link to="#" onClick={onUserDetail} className="nav-user-li">
                                <UserOutlined className="nav-user-icon"></UserOutlined>
                            </Link>

                            <button className="balance-button">฿ {auth.userData?.wallet?.balance?.toFixed(2)}</button>

                            <li className="nav-login">
                                <Link to="#" onClick={logout} className="nav-login-li">ออกจากระบบ</Link>
                            </li>
                        </>
                        )
                        ) : (
                            <>
                                <li className="nav-login">
                                    <Link to="#" onClick={onLoginClick} className="nav-login-li">เข้าสู่ระบบ</Link>
                                </li>

                                <li className="nav-register">
                                    <Link to="#" onClick={onRegisterClick} className="nav-register-li">Register</Link>
                                </li>
                            </>
                        )}
                </ul>
            </nav>
        </header>
    );
}

export default Header;