import React, { useState, useEffect, useMemo } from 'react';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification, message  } from 'antd';
import { EditOutlined, DeleteOutlined } from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import DataTable from '../../table/DataTable';
import './UserDetail.css';
import './Account.css'

function ManageBankAccount(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();
    const [accountData, setAccountData] = useState(null);
    const [isBankAccountAvailable, setIsBankAccountAvailable] = useState(false);
    const [data, setData] = useState([]);
    const navigate = useNavigate();

    const columns = useMemo(() => [
        { Header: 'Bank', accessor: 'bankName' },
        { Header: 'Bank number', accessor: 'accountNumber' },
        { Header: 'Full Name', accessor: 'fullName' },
        {
            Header: 'Actions',
            id: 'actions',
            accessor: 'id',
            Cell: ({ value }) => (
                <div>
                    <EditOutlined onClick={() => handleEdit(value)} style={{ color: 'blue', marginRight: 12, cursor: 'pointer' }} />
                </div>
            )
        }
    ], []);

    useEffect(() => {
        fetchAccount(auth.username); 
        if (auth.userData) {
            setAccountData(auth.userData);
            setData(auth.userData.bankAccounts); // Directly set the bankAccounts array
            setIsBankAccountAvailable(auth.userData.bankAccounts.length > 0);
        }
    }, [auth]);

    const fetchAccount = async(username) => {
        try {
          const response = await fetch(apiUrl+'/member/getuser?username='+username, {
            method: 'GET',
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
          });

          if (!response.ok) {
            throw new Error(`HTTP error! status: ${response.status}`);
          }

          const data = await response.json();
          setAccountData(data);
          setIsBankAccountAvailable(!!accountData.bankAccount);
          console.log(data);
        } catch (error) {
          console.error("Get User DATA failed:", error);
        }
    };

    const handleEdit = (id) => {
        navigate(`/user/detail/managebank/edit/${id}`);
    };

    const handleDelete = async(id) => {    

        const method = "DELETE";

        var apiUrl = `${process.env.REACT_APP_API_URL}/member/bankaccount/delete/`+id;
        
        const requestOptions = {
            method,
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
        };

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();
            message.success('Bank account deleted successfully!');
            await fetchAccount(auth.username);
        } catch (error) {
            console.error('Error deleting member:', error);
        }
    };

    return (
        <div className="user-detail-container" style={{ backgroundImage: 'url(/background_3_pages.png)' }}>
            <Header />
            <div className="user-detail-data-container">
                <h1 style={{ color: 'white' }}>Manage Bank Account</h1>
                <div className="user-detail-data">
                    <Form
                        form={form}
                        className="account-form"
                        layout="vertical"
                        initialValues={{ enable: true }}
                    >
                        <DataTable columns={columns} data={data} />
                        <br></br>
                        <Link to="/user/detail/managebank/add" style={{marginTop:"20"}}>
                            <Button type="primary" danger>Add Bank Account</Button>
                        </Link>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default ManageBankAccount;