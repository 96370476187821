import React from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../../AuthContext';
import Header from '../../layout/UserDetail/Header';
import { Button, Input, Modal, Form, Switch, Layout, theme, Select, notification  } from 'antd';
import './UserDetail.css';

function DepositOnlineTransfer(){

    const { auth } = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [form] = Form.useForm();

    const onFinish = async(values) => {
        console.log('Received values:', values);

        const raw = JSON.stringify({
            "username": auth.username,
            "type": "deposit",
            "method": "online_transfer",
            "promotionCode": values.promocode,
            "amount": parseFloat(values.amount)
        });

        const apiUrl = `${process.env.REACT_APP_API_URL}/member/deposit/online`;
        const requestOptions = {
            method: "POST",
            headers: { "Authorization": `Bearer ${auth.token}`, "Content-Type": "application/json" },
            body: raw
        };

        console.log(raw);

        try {
            const response = await fetch(apiUrl, requestOptions);
            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            const result = await response.json();

            notification.success({ message: `Deposit Request : Added`, description: `The deposit has been successfully updated.` });
        } catch (error) {
            console.error('Error Deposit Request:', error);
        }
    };

    return (
        <div className="user-detail-container" style={{backgroundImage: 'url(/background_3_pages.png)'}}>
            <Header/>
            <div className="user-detail-data-container">

                <h1 style={{color: 'white'}}>Deposit Online Transfer</h1>

                <div className="user-detail-data">
                    
                    <Form form={form} 
                        onFinish={onFinish}
                        className="deposit-form" layout="vertical" 
                        initialValues={{
                        enable: true
                    }}>
                        <h1>Enter Deposit Amount</h1>
                        <Form.Item name="amount" label="Balance" rules={[{ required: true }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item name="promocode" label="Promotion Code" rules={[{ required: false }]}>
                            <Input />
                        </Form.Item>

                        <Form.Item style={{justifySelf: 'center', alignSelf: 'center'}}>
                            <Button type="primary" className="deposit-button" htmlType="submit">
                                Submit
                            </Button>
                        </Form.Item>
                    </Form>
                </div>
            </div>
        </div>
    );
}

export default DepositOnlineTransfer;